export const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PUT: 'PUT',
  PATCH: 'PATCH',
} as const

// export const baseUrl = process.env.REACT_APP_REST_ENDPOINT || 'https://dev.api.im-pulse.ai/'
export const baseUrl = process.env.REACT_APP_REST_ENDPOINT || 'https://api.im-pulse.ai/'

export const REQUEST_STATUS = {
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
} as const

export const TOKEN = {
  REFRESH: 'refresh',
  ACCESS: 'access',
} as const
