import { CircularProgress, IconButton, Tooltip } from '@mui/material'
import { useLazyGetClassifierLinksQuery } from 'api'
import { UploadIcon } from 'assets/svg'
import { UploadButton } from 'modules/common/components'
import { useMessage } from 'modules/errorHandler/useMessage'
import { useShowError } from 'modules/errorHandler/useShowError'
import { uploadFile } from 'modules/files/actions'
import { useText } from 'modules/locale'
import React, { FC, useEffect, useState } from 'react'

type Props = { id: string, reFetch: () => void }

const EXCEL = 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export const UploadActionButton: FC<Props> = ({ id, reFetch }) => {
  const [isFetching, setIsFetching] = useState(false)
  const [isSuccess, setIsSuccess] = useState('')
  const [error, setError] = useState('')

  const { text } = useText('CLASSIFIERS')
  const [getLinks] = useLazyGetClassifierLinksQuery()

  useShowError(error)
  useMessage(isSuccess)

  const handleUpload = async (file: File) => {
    setIsFetching(true)

    try {
      await getLinks({ id })
        .unwrap()
        .then(({ upload_tree_link }) => {
          const { url, fields: params } = upload_tree_link
          return uploadFile({ url, params, file, fileName: id })
        })
        .then(() => {
          setIsSuccess(text.successFileUploaded)
          reFetch()
        })
    } catch (err) {
      console.error(err)
      setError(text.uploadError)
    } finally {
      setIsFetching(false)
    }
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      setError('')
      setIsSuccess('')
    })
    return () => {
      clearTimeout(timer)
    }
  }, [error, isSuccess])

  return (
    <>
      <UploadButton id={id} customButton fileType={EXCEL} onUpload={handleUpload}>
        <Tooltip title={text.upload}>
          <IconButton component='span' size='small'>
            {isFetching ? <CircularProgress size={24} /> : <UploadIcon />}
          </IconButton>
        </Tooltip>
      </UploadButton>
    </>

  )
}
