import { Button, ButtonProps, styled } from '@mui/material'
import { ACTION } from 'modules/errorHandler/Notistack'
import { useText } from 'modules/locale'
import { useSnackbar } from 'notistack'
import React, { ChangeEvent } from 'react'
import { ReactComponent as File } from '../../assets/developer/file.svg'

type Props = ButtonProps & {
  fileType?: string // MIME-type
  onUpload?: (file: File) => void
  customButton?: boolean
}

export const UploadButton: React.FC<Props> = ({ fileType, onUpload, customButton = false, children, ...props }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { text } = useText('ERRORS')

  const handleUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files?.[0]
    if (uploadedFile && uploadedFile.size > 10 * 1024 * 1024) {
      e.target.value = ''
      enqueueSnackbar(`${text.fileSizeIsLarge} 10Мb`, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
    if (uploadedFile && onUpload) {
      onUpload(uploadedFile)
    }
  }

  return (
    <>
      <Label htmlFor={props.id ? 'upload-button' + props.id : 'upload-button'} full_width={props.fullWidth}>
        <input
          id={props.id ? 'upload-button' + props.id : 'upload-button'}
          accept={fileType || '*'}
          type='file'
          style={{ display: 'none' }}
          disabled={props.disabled}
          name={props.name}
          onChange={handleUploadFile}
        />

        {customButton
          ? children
          : <Button {...props} component='span'>{children}</Button>
        }
      </Label>
    </>
  )
}

const Label = styled('label')<{ full_width?: boolean }>`
  width: ${({ full_width }) => full_width ? '100%' : 'auto'};
`
