import { Box } from '@mui/material'
import { Cover, ResetPassword, RestorePassword, SignIn, SignUp, SignUpByInvitation } from 'modules/auth/components'
import { Logo } from 'modules/common/components'
import { ROUTE } from 'modules/common/routes'
import { Locale } from 'modules/locale/components'
import React, { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useText } from 'modules/locale'
import styled from 'styled-components'
import { color } from 'styles/theme'
import { ABOUT_PROJECT_LINK } from 'modules/common/components/Header/constants'

export const Auth: FC = () => {
  const { text } = useText('HEADER')
  return (
    <>
      <Box component='main' display='flex'>
        <Box width='100%' minWidth='700px' display='flex' justifyContent='flex-end'>
          <Box p='0 100px' display='flex' flexDirection='column' maxWidth='600px'>
            <Box display='flex' justifyContent='space-between' alignItems='center' py='20px' mb='100px'>
              <Logo />
              <Link href={ABOUT_PROJECT_LINK} target='_blank'>
                {text.aboutProduct}
              </Link>
              <Locale />
            </Box>

            <Routes>
              <Route path={`${ROUTE.USER_INVITATION}/:guid`} element={<SignUpByInvitation />} />

              <Route path={ROUTE.SIGN_IN} element={<SignIn />} />
              <Route path={ROUTE.SIGN_UP} element={<SignUp />} />
              <Route path={ROUTE.RESET_PASSWORD} element={<ResetPassword />} />
              <Route path={ROUTE.RESTORE_PASSWORD} element={<RestorePassword />} />

              <Route path='*' element={<Navigate to={ROUTE.SIGN_IN} />} />
            </Routes>
          </Box>
        </Box>

        <Cover />

      </Box>
    </>
  )
}

const Link = styled.a`
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  padding: 20px 40px;
  border-radius: 5px;
  color: ${color.medium};
  margin-right: auto;
  text-decoration: none;
`
